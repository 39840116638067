.navContainer {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 1000000;
}
.logo {
  width: 100px;
  height: 70px;
  object-fit: contain;
}
.hideBg {
  /* top: 20px; */
  background-color: transparent;
  border-bottom: none;
}
.showBg {
  background-color: white;
  border-bottom: 2px solid var(--mainColor);
}
.sidebar {
  position: fixed;
  top: 0;
  background-color: white;
  height: 100vh;
  width: 320px;
  padding: 10px;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.show {
  right: 0;
  opacity: 1;
}
.hide {
  right: -300%;
  opacity: 0;
}
.close {
  position: absolute;
  top: 10px;
  left: 10px;
}
