* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0 !important;
  margin: 0 !important;
}
body {
  overflow-x: hidden;
  text-transform: capitalize;
  font-family: "Cairo", sans-serif;
}
ul {
  list-style-type: none;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none !important;
}
:root {
  --mainColor: #1a75bc;
}
.green {
  color: var(--mainColor);
}
.btn,
.langbtn {
  display: flex;
  width: 150px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  background-color: var(--mainColor);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.langbtn {
  width: 50px;
  border: none;
}
.image-overlay-container {
  position: relative;
  overflow: hidden;
}
.zoom-in-image {
  animation: zoomEffect 10s infinite alternate;
  transition: transform 0.7s;
  -webkit-transition: transform 0.7s;
  -moz-transition: transform 0.7s;
  -ms-transition: transform 0.7s;
  -o-transition: transform 0.7s;
}

@keyframes zoomEffect {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
  }
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the overlay color and opacity as needed */

  transition: opacity 0.3s;
}

.image-title {
  color: var(--mainColor);
  font-size: 24px;
  text-align: center;
}
.mySlider {
  width: 100%;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.btn:hover {
  background-color: #1a75bc !important;
}
