.mainContainer {
  position: relative;
  width: 100vw;
}
.mainImg {
  width: 100vw;
  height: 85vh;
  object-fit: cover;
}

.life {
  color: var(--mainColor);
}
.statContainer {
  padding-top: 50px;
}
@media screen and (max-width: 700px) {
  .statContainer {
    position: relative !important;
    bottom: 0;
    margin: 20px 0;
  }
}
.mainCard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 250px;
  box-shadow: 6px 6px 0px 0px var(--mainColor);
  background-color: #fff;
  border: 1.5px solid #000;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
@media screen and (max-width: 500px) {
  .mainCard {
    width: 300px;
  }
}
.num {
  font-size: 60px;
}
@media screen and (max-width: 500px) {
  .num {
    font-size: 33px;
  }
}
