.imgContainer {
  position: relative;
  width: 595px;
  height: 500px !important;
  overflow: hidden;
}
@media screen and (max-width: 630px) {
  .imgContainer {
    width: 100%;
    height: 250px !important;
  }
}
.contentContainer {
  position: relative;
}
.contentContainer::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #9bb7bf;
  opacity: 0.5;
}
.title {
  color: #141414;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.desc {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.card {
  margin: 0 !important;
  display: flex !important;
  flex-direction: column;
  /* padding: 0 !important; */
  max-width: 100%;
  height: auto;
  display: flex !important;
  width: 100% !important;
}
.card-body {
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card img {
  max-width: 100%;
  width: 100% !important;
  height: 200px;
  object-fit: cover;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-top-left-radius: calc(0.25rem - 1px);
}
.cardRow {
  flex-direction: row !important;
}
.cardRowReverse {
  flex-direction: row-reverse !important;
}
.card-title {
  font-size: 26px;
  margin-bottom: 20px !important;
}
.card-text {
  line-height: 25px !important;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to three lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  color: #606060;
  text-align: center;
  min-height: 75px;
}

@media (max-width: 768px) {
  .cardRow {
    flex-direction: column !important;
  }
  .cardRowReverse {
    flex-direction: column !important;
  }
  .card img {
    max-width: 100%;
    height: 300px;
    width: 100%;
  }
  .card-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-title {
    font-size: 24px;
    margin-bottom: 20px !important;
  }
  .card-text {
    line-height: 25px !important;
    font-size: 16px;
    color: #606060;
  }
}

@media (max-width: 512px) {
  .card img {
    max-width: 100%;
    height: 250px;
    width: 100%;
  }

  .card-title {
    font-size: 22px;
    margin-bottom: 20px !important;
  }
  .card-text {
    line-height: 30pt;
    font-size: 15px;
    color: #606060;
  }
  .card {
    width: 100% !important;
  }
}
