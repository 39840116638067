.imgContainer {
  position: relative;
  width: 595px;
  height: 500px !important;
  overflow: hidden;
}
@media screen and (max-width: 630px) {
  .imgContainer {
    width: 100%;
    height: 250px !important;
  }
}
.contentContainer {
  position: relative;
}
.contentContainer::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #9bb7bf;
  opacity: 0.5;
}
.title {
  color: #141414;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.desc {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.36px;
}
.serviceCard {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  max-width: 100%;
  height: auto;
  display: flex !important;
  width: 100% !important;
  border: 1px solid lightgray;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.card-body {
  padding: 20px !important;
}
.serviceCard .imgLink {
  max-width: 40%;
}
.serviceCard img {
  max-width: 100%;
  height: 400px;
  object-fit: fill;
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-top-left-radius: calc(0.25rem - 1px);
}
.cardRow {
  flex-direction: row !important;
}
.cardRowReverse {
  flex-direction: row-reverse !important;
}
.card-title {
  font-size: 28px;
  margin-bottom: 20px !important;
}
.card-text {
  line-height: 30pt;
  font-size: 18px;
  color: #606060;
}

@media (max-width: 768px) {
  .cardRow {
    flex-direction: column !important;
  }
  .cardRowReverse {
    flex-direction: column !important;
  }
  .serviceCard .imgLink {
    max-width: 100%;
  }
  .serviceCard img {
    width: 100%;
    height: auto;
    width: 100%;
  }
  .card-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-title {
    font-size: 24px;
    margin-bottom: 20px !important;
  }
  .card-text {
    line-height: 30pt;
    font-size: 16px;
    color: #606060;
  }
}

@media (max-width: 512px) {
  .serviceCard .imgLink {
    max-width: 100%;
  }
  .serviceCard img {
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  .card-title {
    font-size: 22px;
    margin-bottom: 20px !important;
  }
  .card-text {
    line-height: 30pt;
    font-size: 15px;
    color: #606060;
  }
  .serviceCard {
    width: 100% !important;
  }
}
