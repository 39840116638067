.aboutContainer {
  justify-content: space-evenly;
}
.title {
  color: #000;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.72px;
}
.aboutdesc {
  width: 100%;
  color: gray;
  font-size: 18px;
  line-height: 35px !important;
}

.desc {
  color: grey;
  font-size: 18px;
  line-height: 40px;
}
.aboutImg {
  width: 427px;
  height: 373px;
  object-fit: cover;
  border-radius: 30% 70% 35% 65% / 30% 30% 70% 70%;
  -webkit-border-radius: 30% 70% 35% 65% / 30% 30% 70% 70%;
  -moz-border-radius: 30% 70% 35% 65% / 30% 30% 70% 70%;
  -ms-border-radius: 30% 70% 35% 65% / 30% 30% 70% 70%;
  -o-border-radius: 30% 70% 35% 65% / 30% 30% 70% 70%;
}
@media screen and (max-width: 991px) {
  .aboutContainer {
    flex-direction: column;
  }
  .AboutTextContainer {
    width: 100% !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
