.Storefloating-button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.Storefloating-button {
  width: 60px;
  height: 60px;
  background-color: #1a75bc;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
  position: fixed;
  bottom: 105px;
  right: 30px;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.Storefloating-button:hover {
  background-color: #fcb040;
}

.icon {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.options {
  position: absolute;
  bottom: 80px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.options.open {
  transform: translateX(0);
}
