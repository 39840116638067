footer {
  padding: 2vmax;
  padding-top: 40px;
  background-color: #fff;
  color: #0077b5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 30vh;
  align-items: flex-start;
}

.phoneNumberHeading {
  color: gray !important;
}
.phoneNumberCointainer {
  margin-bottom: 10px !important;
}
.phoneNumber {
  direction: ltr;
  margin-top: 10px !important;
}
.phoneNumber a {
  font-size: 20px;
  color: #0077b5;
  font-weight: bold;
  box-shadow: inset 0 0 0 0 #0077b5;
  margin: 0 -0.25rem;
  padding: 0 0.2rem;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.phoneNumber a:hover {
  color: #fcb040;
  box-shadow: inset 200px 0 0 0 #0077b5;
  color: white;
}

.leftFooter {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftFooter > h4 {
  font-family: "Roboto";
  font-size: 1.4vmax;
  margin-bottom: 10px;
  text-align: right;
}

.leftFooter > p {
  text-align: center;
  padding-bottom: 10px !important;
  color: gray;
  /* font-size: 1.2vmax; */
}

.midFooter {
  width: 33%;
  text-align: center;
}

.midFooter > h1 {
  font-size: 4vmax;
  font-family: "Roboto";

  margin: 0;
  letter-spacing: 10px;
}

.midFooter > p {
  max-width: 60%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 1vmax auto;
}

.midFooter > h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.rightFooter {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.rightFooter > h4 {
  font-size: 22px;
  margin-bottom: 10px;
  text-align: right;
  text-align: center;
}

.rightFooter > a {
  text-decoration: none;
  font-size: 1.3vmax;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  transition: all 0.5s;
  margin: 0.5vmax;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
  }
  .leftFooter,
  .midFooter,
  .rightFooter {
    width: 100%;
    text-align: center;
  }

  /* Add more specific adjustments as needed */
}

.copyrights {
  background-color: #fcb040;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footerContainer {
  display: flex;
  flex-direction: column;
}
.card {
  width: fit-content;
  height: fit-content;
  background-color: rgb(238, 238, 238);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  padding: 25px 25px;
  gap: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
  border-radius: 5px;
  background-color: rgb(44, 44, 44);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition-duration: 0.3s;
  padding: 10px;
}
/* instagram*/
.containerOne:hover {
  background-color: #d62976;
  transition-duration: 0.3s;
}
/* twitter*/
.containerTwo:hover {
  background-color: #00acee;
  transition-duration: 0.3s;
}
/* linkdin*/
.containerThree:hover {
  background-color: #fffc00;
  transition-duration: 0.3s;
}
/* Whatsapp*/
.containerFour:hover {
  background-color: #0072b1;
  transition-duration: 0.3s;
}

.socialContainer:active {
  transform: scale(0.9);
  transition-duration: 0.3s;
}

.socialSvg {
  width: 20px;
}

.socialSvg path {
  fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
  animation: slide-in-top 0.3s both;
  -webkit-animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.card.horizontal {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  border: none;
  box-shadow: none;
}
.footer-component {
  padding-top: 20px;
}
