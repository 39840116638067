.title {
  color: var(--mainColor);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.desc {
  color: #141414;
  font-size: 45px;
  font-weight: 700;
  letter-spacing: -0.45px;
  width: 50%;
}
@media screen and (max-width: 600px) {
  .desc {
    font-size: 30px;
    width: 100%;
  }
}
@media screen and (max-width: 567px) {
  .card-text {
    margin-bottom: 0.5em;
  }
}
.ServiceContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}
