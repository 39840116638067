.mainDiv {
  margin-top: 30px !important;
  background-color: #fff;
}
.title {
  color: #141414;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.45px;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  .title {
    font-size: 30px;
  }
}
.img {
  width: 143.385px;
  height: 50px;
  object-fit: cover;
}
.swiper-slide {
  display: flex !important;
  justify-content: center !important;
}
.swiper-slide img {
  object-fit: contain;
}
