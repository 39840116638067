.banner {
  position: relative;
  width: 100%;
  height: 500px; /* You can adjust the height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
}

.banner-content {
  text-align: center;
  z-index: 11;
}

.banner-title {
  color: white;
  font-size: 4rem; /* Adjust font size as needed */
}
